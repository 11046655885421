<template>
  <div
    ref="wrapper"
    class="location-map-wrapper"
  >
    <div
      ref="map"
      class="map"
      style="height: calc(100vh)"
    />
    <v-tooltip
      right
      :absolute="true"
      :position-x="$vuetify.breakpoint.width * 0.009 + 60"
      :position-y="$vuetify.breakpoint.height - 50 - (($vuetify.breakpoint.width * 0.009 + 40) * 1.5)"
      :value="editButtonTooltipVisible"
      :max-width="300"
    >
      Draw new polygon
    </v-tooltip>
    <v-tooltip
      v-if="canDelete"
      right
      :absolute="true"
      :position-x="$vuetify.breakpoint.width * 0.009 + 60"
      :position-y="$vuetify.breakpoint.height - 50 - (($vuetify.breakpoint.width * 0.009 + 40) * 0.5)"
      :value="deleteButtonTooltipVisible"
      :max-width="300"
    >
      {{ deleteButtonText }}
    </v-tooltip>
    <div class="interaction-bar px-2 pt-7">
      <v-tooltip
        v-if="$vuetify.breakpoint.smAndUp"
        right
      >
        <template #activator="{ on }">
          <v-btn
            icon
            large
            color="leaf"
            :style="{ visibility: project && project.location.area ? 'visible' : 'hidden' }"
            :to="`/projects/${projectId}`"
            v-on="on"
          >
            <v-icon large>
              mdi-arrow-left
            </v-icon>
          </v-btn>
        </template>
        Back to project overview
      </v-tooltip>
      <div
        class="display-2 leaf--text mx-4 d-flex flex-column"
        :class="{ 'display-1': $vuetify.breakpoint.smAndDown }"
      >
        Draw your site boundary
        <span
          v-if="displayedArea"
          class="display-1 mt-3"
          :class="{ 'headline': $vuetify.breakpoint.smAndDown }"
        >{{ displayedArea }}</span>
      </div>
    </div>
    <div class="check floating-button">
      <v-btn
        v-if="displayedArea"
        fab
        x-large
        color="white"
        class="ma-10"
        @click="manualAreaInputDialogVisible = true"
      >
        <v-icon x-large>
          mdi-check
        </v-icon>
      </v-btn>
    </div>
    <v-menu
      v-model="helpMenuOpen"
      close-on-click
      close-on-content-click
      :nudge-bottom="15"
      offset-y
      left
    >
      <template v-slot:activator="{ on }">
        <div class="question floating-button">
          <v-btn
            fab
            :small="$vuetify.breakpoint.xsOnly"
            color="white"
            v-on="on"
          >
            <v-icon
              :small="$vuetify.breakpoint.xsOnly"
            >
              mdi-help
            </v-icon>
          </v-btn>
        </div>
      </template>
      <v-card
        width="500"
        style="pointer-events: none;"
      >
        <v-card-title class="title py-3">
          Drawing your site boundary
        </v-card-title>
        <v-divider />
        <v-card-text>
          <ul>
            <li class="mb-2">
              Explore the map to find your site's location, or use the search bar to fly there.
            </li>
            <li class="mb-2">
              Click the <v-icon>mdi-shape-polygon-plus</v-icon> button in the bottom left to begin drawing an area.
            </li>
            <li class="mb-2">
              Use the mouse to draw your project area.
              <ul class="mt-2">
                <li class="mb-2">
                  Add vertices to your shape by clicking a point on the map.
                </li>
                <li class="mb-2">
                  Close your shape by clicking again on the initial vertice.
                </li>
              </ul>
            </li>
            <li class="mb-2">
              Edit your shape by clicking on it after you're finished editing.
              <ul class="mt-2">
                <li class="mb-2">
                  Select a vertice by clicking on it so that it highlights green.
                </li>
                <li class="mb-2">
                  You can now drag the vertice to move it, or click the <v-icon>mdi-delete</v-icon> button to delete it.
                </li>
                <li class="mb-2">
                  Add a new vertice by first selecting an existing vertice, then clicking one of the line midpoints.
                </li>
              </ul>
            </li>
            <li class="mb-2">
              Delete a shape by first selecting it, then clicking the <v-icon>mdi-delete</v-icon> button.
            </li>
            <li class="mb-2">
              When you're finished drawing, use the <v-icon>mdi-check-circle-outline</v-icon> button to proceed.
            </li>
          </ul>
        </v-card-text>
      </v-card>
    </v-menu>
    <ManualAreaInputDialog
      :show.sync="manualAreaInputDialogVisible"
      :calculated-area="calculatedAreaInM2"
      :area-in-m2.sync="finalAreaInM2"
      :system-of-measurement="project && project.systemOfMeasurement"
      @back="manualAreaInputDialogVisible = false"
      @next="saveChanges"
    />
  </div>
</template>

<script>
import cleanAxios from 'axios'

import Mapbox from 'mapbox-gl'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import MapboxDraw from '@mapbox/mapbox-gl-draw'

import ManualAreaInputDialog from '@/components/modals/ManualAreaInputDialog'

import mapboxDrawStyle from './mapbox-draw-style'

import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import 'mapbox-gl/dist/mapbox-gl.css'

import turfCentroid from '@turf/centroid'
import turfArea from '@turf/area'
// import turfBbox from '@turf/bbox'
// import { convertArea } from '@turf/helpers'
import getReasonableAreaUnits from '@/helpers/getReasonableAreaUnits'

import { createNamespacedHelpers, mapActions } from 'vuex'
const { mapGetters: mapProjectGetters, mapMutations: mapProjectMutations, mapActions: mapProjectActions } = createNamespacedHelpers('projects')

export default {
  name: 'ProjectLocation',
  components: {
    ManualAreaInputDialog
  },
  props: {
    projectId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      accessToken: 'pk.eyJ1IjoiZWRhbndlaXMiLCJhIjoiY2lmMTVtdWQ0MDRsOHNkbTV2OXd3cDNwNiJ9.MxWj73wGNEvrPSjsh6TJjw',
      editButtonTooltipVisible: false,
      deleteButtonTooltipVisible: false,
      selected: null,
      drawing: false,
      directSelect: false,
      helpMenuOpen: false,
      canDelete: false,
      deleteButtonHasBeenEnabledOnceOrMore: false,
      draw: null,
      calculatedAreaInM2: 0,
      finalAreaInM2: 0,
      areaUnits: 'square feet',
      manualAreaInputDialogVisible: false,
      initialized: false,
      mounted: false
    }
  },
  computed: {
    ...mapProjectGetters(['project']),

    deleteButtonText () {
      switch (this.selected) {
        case 'node':
          return 'Delete selected polygon node'
        case 'area':
          return 'Delete selected area'
        default:
          return 'Delete current shape'
      }
    },
    displayedArea () {
      if (!this.calculatedAreaInM2) {
        return ''
      }
      const system = this.project ? this.project.systemOfMeasurement : 'imperial'
      const { value, units } = getReasonableAreaUnits(this.calculatedAreaInM2, system)
      return `${value} ${units}`
    }
  },
  watch: {
    canDelete (val, oldVal) {
      const method = val ? 'remove' : 'add'
      this.$refs.wrapper.getElementsByClassName('mapbox-gl-draw_trash')[0].classList[method]('disabled')
      if (val) {
        if (!this.deleteButtonHasBeenEnabledOnceOrMore) {
          this.hideEditButtonTooltip()
          setTimeout(() => {
            this.showDeleteButtonTooltip()
            setTimeout(() => {
              this.hideDeleteButtonTooltip()
            }, 3000)
          }, 1000)
        }
        this.deleteButtonHasBeenEnabledOnceOrMore = true
      }
    },
    displayedArea (val) {
      const method = val ? 'add' : 'remove'
      this.$refs.wrapper.getElementsByClassName('mapboxgl-ctrl-geocoder')[0].classList[method]('showing-area')
    },
    calculatedAreaInM2 (val) {
      this.finalAreaInM2 = val
    },
    project: {
      immediate: true,
      handler (project) {
        if (project && this.mounted) {
          this.initialize()
        }
      }
    }
  },
  async created () {
    if (this.projectId) {
      try {
        if (!this.project || this.project._id !== this.projectId) {
          await this.fetchProjectById(this.projectId)
        }
      } catch (err) {
        this.$router.push('/projects')
        throw err
      }
    } else {
      this.$store.commit('setIsOnboarding', true)
    }
  },
  mounted () {
    this.mounted = true
    this.initialize()
  },
  beforeDestroy () {
    const editButton = this.$refs.wrapper.querySelector('.mapbox-gl-draw_polygon')
    editButton.removeEventListener('mouseenter', this.showEditButtonTooltip)
    editButton.removeEventListener('mouseleave', this.hideEditButtonTooltip)
    const deleteButton = this.$refs.wrapper.querySelector('.mapbox-gl-draw_trash')
    deleteButton.removeEventListener('mouseenter', this.showDeleteButtonTooltip)
    deleteButton.removeEventListener('mouseleave', this.hideDeleteButtonTooltip)
  },
  methods: {
    ...mapActions(['showSnackbar']),
    ...mapProjectMutations(['updateNewProject']),
    ...mapProjectActions(['fetchProjectById', 'updateProjectLocation']),

    initialize () {
      if (!this.initialized) {
        this.initialized = true
        this.loadMap()
        this.addTooltipListeners()
        this.$refs.wrapper.getElementsByClassName('mapbox-gl-draw_trash')[0].classList.add('disabled')
      }
    },
    getBoundingBox (data) {
      // const bounds = this.project.location.polygons.reduce((acc, polygon) => [...acc, ...polygon.geometry.coordinates.reduce((acc, value) => [...acc, ...value], [])], [])
      let bounds = {}
      let coords
      let latitude
      let longitude
      for (let i = 0; i < this.project.location.polygons.length; i++) {
        coords = this.project.location.polygons[i].geometry.coordinates
        for (let j = 0; j < coords.length; j++) {
          for (let k = 0; k < coords[j].length; k++) {
            longitude = coords[j][k][0]
            latitude = coords[j][k][1]
            bounds.xMin = bounds.xMin < longitude ? bounds.xMin : longitude
            bounds.xMax = bounds.xMax > longitude ? bounds.xMax : longitude
            bounds.yMin = bounds.yMin < latitude ? bounds.yMin : latitude
            bounds.yMax = bounds.yMax > latitude ? bounds.yMax : latitude
          }
        }
      }
      return [[bounds.xMin, bounds.yMin], [bounds.xMax, bounds.yMax]]
    },
    loadMap () {
      const locationMap = new Mapbox.Map({
        container: this.$refs.map,
        style: 'mapbox://styles/edanweis/ck21qftxa0mr81dk9h5bf4766',
        accessToken: this.accessToken,
        center: [-73.984495, 40.756027],
        zoom: 3,
        attributionControl: false
      })
      const searchBar = new MapboxGeocoder({
        accessToken: this.accessToken,
        zoom: 14,
        marker: false,
        placeholder: 'Search for a location'
      })
      searchBar.on('result', (res) => {
        setTimeout(() => {
          this.showEditButtonTooltip()
          setTimeout(() => {
            this.hideEditButtonTooltip()
          }, 10000)
        }, 5000)
      })

      locationMap.addControl(searchBar, 'top-left')
      this.draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: true,
          trash: true
        },
        position: 'bottom',
        styles: mapboxDrawStyle
      })
      if (this.project) {
        setTimeout(() => {
          if (this.project.location.area > 0) {
            const bounds = this.getBoundingBox()
            const existingData = {
              type: 'FeatureCollection',
              features: this.project.location.polygons.map(x => ({ ...x, properties: x.properties || {} }))
            }
            this.draw.set(existingData)
            locationMap.fitBounds(bounds)
          }
          this.setArea()
        }, 1000)
      }

      locationMap.addControl(this.draw, 'bottom-left')

      if (this.project) {
        setTimeout(() => {
          this.showEditButtonTooltip()
          setTimeout(() => {
            this.hideEditButtonTooltip()
          }, 5000)
        }, 5000)
      }

      locationMap.on('draw.selectionchange', e => {
        const setSelected = (newVal, helpText) => {
          if (this.selected !== newVal) {
            this.showSnackbar({ text: helpText, timeout: 6000, color: 'metal' })
          }
          this.selected = newVal
        }
        if (e.points.length) {
          setSelected('node', 'Vertice selected. Drag the vertice to move it, or click the delete button to remove this vertice')
        } else if (e.features.length && !this.directSelect) {
          setSelected('area', 'Shape selected. Select a vertice to edit it, or click the delete button to delete the shape')
        } else {
          this.selected = null
        }
      })
      locationMap.on('draw.modechange', ({ mode }) => {
        this.drawing = mode === 'draw_polygon'
        this.directSelect = mode === 'direct_select'
        if (mode === 'direct_select') {
          this.showSnackbar({ text: 'Edit mode enabled. Select a vertice to edit it, or click a midpoint to add a new vertice.', timeout: 6000, color: 'metal' })
        }
      })
      locationMap.on('draw.create', e => {
        this.setArea()
        this.$nextTick(() => {
          this.showSnackbar({ text: 'New shape created. Select a vertice to edit it, or click the delete button to delete the shape', timeout: 6000, color: 'metal' })
        })
      })
      locationMap.on('draw.actionable', e => {
        this.canDelete = e.actions.trash
      })
      locationMap.on('draw.delete', (e) => {
        this.setArea()
        this.drawing = false
        this.selected = null
        this.directSelect = false
      })
      locationMap.on('draw.update', (e) => {
        this.setArea()
      })
    },
    showDeleteButtonTooltip () {
      this.deleteButtonTooltipVisible = true
    },
    hideDeleteButtonTooltip () {
      this.deleteButtonTooltipVisible = false
    },
    showEditButtonTooltip () {
      this.editButtonTooltipVisible = true
    },
    hideEditButtonTooltip () {
      this.editButtonTooltipVisible = false
    },
    addTooltipListeners () {
      const editButton = this.$refs.wrapper.querySelector('.mapbox-gl-draw_polygon')
      editButton.removeAttribute('title')
      editButton.addEventListener('mouseenter', this.showEditButtonTooltip)
      editButton.addEventListener('mouseleave', this.hideEditButtonTooltip)
      const deleteButton = this.$refs.wrapper.querySelector('.mapbox-gl-draw_trash')
      deleteButton.removeAttribute('title')
      deleteButton.addEventListener('mouseenter', this.showDeleteButtonTooltip)
      deleteButton.addEventListener('mouseleave', this.hideDeleteButtonTooltip)
    },
    setArea () {
      this.calculatedAreaInM2 = turfArea(this.draw.getAll())
    },
    async saveChanges () {
      const drawData = this.draw.getAll()
      const polygons = drawData.features
      const centroid = turfCentroid(drawData)
      let latlng = centroid.geometry.coordinates.reverse()
      latlng = latlng.map(x => Math.round(x * 100) / 100)
      const latlngEncoded = encodeURIComponent(`${latlng[0]},${latlng[1]}`)
      const { data } = await cleanAxios.get(`https://api.opencagedata.com/geocode/v1/json?q=${latlngEncoded}`, {
        params: {
          key: 'b9cdea592abf49dea33dba126eab0afb',
          pretty: 1,
          no_annotations: 1,
          limit: 1,
          language: 'en'
        }
      })
      const res = data.results[0]
      const { components, formatted: address } = res
      const { country, city, state } = components
      try {
        const location = { area: this.finalAreaInM2, centroid, polygons, address, country, state, city, drawData }
        if (this.project) {
          // if a project is being edited, save its location data directly to the database
          await this.updateProjectLocation(location)
          this.$router.push(`/projects/${this.projectId}`)
        } else {
          // if this is part of onboarding, save the project location in state for now until the project is created
          this.updateNewProject({ location })
          // this.$router.push(`/guide`)
          const isAuthenticated = Boolean(this.$store.state.userId)
          if (isAuthenticated) {
            this.$router.push('/projects/new')
          } else {
            this.$router.push(`/start`)
          }
        }
      } catch (err) {
        this.showSnackbar({ color: 'error', text: 'Unable to save site location. Please get in touch.' })
        throw err
      }
    }
  }
}
</script>

<style lang="scss">
.location-map-wrapper {
  position: relative;
  .interaction-bar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    pointer-events: none;
    a {
      pointer-events: all;
      * {
        pointer-events: none;
      }
    }
  }
  .floating-button {
    position: absolute;
    right: 0;
    &.check {
      bottom: 0;
    }
    &.question {
      top: 0;
      margin-top: 3vh;
      margin-right: 3vh;
    }
  }
  .map {
    .mapboxgl-ctrl-geocoder {
      margin-left: 25px;
      transform: scale(0.7);
      position: relative;
      &.showing-area {
        top: 40px;
      }
      transform-origin: top left;
      margin-top: 125px;
      @media only screen and (min-width: 422px) {
        margin-top: 90px;
        transform: scale(0.85);
      }
      @media only screen and (min-width: 640px) {
        transform: scale(1);
      }
      @media only screen and (min-width: 960px) {
        margin-top: 110px;
        margin-left: 70px;
      }
      &, input {
        width: 400px;
        max-width: calc(100vw * 1.1);
      }
    }
    .mapbox-gl-draw_ctrl-draw-btn{
      width: calc(0.9vw + 40px);
      height: calc(0.9vw + 40px);
      box-shadow: none;
      &.active {
        background-color: #d8dee2;
      }
    }
    .mapboxgl-ctrl-bottom-left .mapboxgl-ctrl-group {
      margin-left: 3vh;
      margin-bottom: 3vh;
    }
    .mapbox-gl-draw_trash{
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNNiAxOWMwIDEuMS45IDIgMiAyaDhjMS4xIDAgMi0uOSAyLTJWN0g2djEyek0xOSA0aC0zLjVsLTEtMWgtNWwtMSAxSDV2MmgxNFY0eiIvPjxwYXRoIGQ9Ik0wIDBoMjR2MjRIMHoiIGZpbGw9Im5vbmUiLz48L3N2Zz4=");
      position: relative;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      &.disabled {
        background-color: #fff !important;
        pointer-events: none;
        &::after {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          content: "";
          opacity: 0.8;
          background-color: #ccc !important;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
    }
    .mapbox-gl-draw_polygon{
      background-size: 50%;
      background-image: url("/img/polygon-icon.png");
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }
}
</style>
